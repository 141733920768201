<template>
  <div :class="['mc__card', grid ? 'grid ' : 'list']" @click="navigateTo">
    <img
      src="@/assets/images/certificates/badge-success.svg"
      alt="badge"
      width="25"
      height="27"
      class="mc__card--img"
    />
    <h6 class="mc__card--description">
      {{ credential.credential_title }}
    </h6>

    <div class="mc__card--date">
      <img
        src="@/assets/images/my-achievements/calendar.svg"
        alt="badge"
        width="12"
        height="13"
        v-if="grid"
      />
      <p class="mc__card--date-content">
        {{ credential.created_at | formatDate }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    credential: {
      type: Object,
      required: true
    },
    courseId: {
      type: String,
      required: true
    },
    grid: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(["language"])
  },
  methods: {
    navigateTo() {
      this.$router
        .push({
          path: `/my-achievements/${this.courseId}/awards/credential`,
          query: {
            key: this.credential.key,
            id: this.credential.id,
            language: this.language
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.mc__card {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  border-radius: 4px;
  padding: 8px 16px;
  height: 137px;
  cursor: pointer;
  @include center;
  &:hover {
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
  }
  &.list {
    box-shadow: none;
    background: transparent;
    padding: 8px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    &:hover {
      background: $brand-primary-100;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    }
    .mc__card--img {
      margin-bottom: 0;
    }
    .mc__card--description {
      flex-grow: 1;
      text-align: left;
      margin: 0 0 0 16px;
    }
    .mc__card--date {
      .mc__card--date-content {
        @include label-large($brand-neutral-700, 500);
      }
    }
  }
  .mc__card--img {
    margin-bottom: 18px;
  }
  .mc__card--description {
    @include body-regular($brand-neutral-900);
    @include line-clamp(2);
    margin-bottom: 8px;
    text-align: center;
  }
  .mc__card--date {
    @include flex-horizontal-center;
    .mc__card--date-content {
      @include label-small;
      color: $brand-neutral-300;
      margin: 0 0 0 8px;
    }
  }
}
@media screen and (max-width: 480px) {
  .micro-credentials .micro-credentials_description {
    text-align: left;
  }
}
</style>
