<template>
  <LayoutManager>
    <div class="h-300" v-if="isLoading">
      <Loader />
    </div>
    <div class="ma-details__wrapper" id="my-achievements-details" v-else>
      <div class="ma-details__left">
        <img
          class="ml-1"
          :src="require(`@/assets/images/my-achievements/back.svg`)"
          alt="badge"
          width="12"
          height="12"
          @click="navigateTo('my-achievements')"
        />
      </div>
      <div class="ma-details__right">
        <div class="ma-details--top">
          <h4 class="ma-details__heading--title">
            {{ certificateDetail.display_name }}
          </h4>
          <div
            class="ma-details___content"
            v-if="certificateDetail.is_certificate_available"
          >
            <div class="ma-details___content--left">
              <p class="ma-details__subtitle">
                {{ $t("my_achievements.detail.title") }}
              </p>
              <p class="ma-details___description">
                {{ $t("my_achievements.detail.sub_heading") }}
              </p>
              <div class="ma-details__cta">
                <div
                  class="awards-cta--download"
                  @click="downloadCertificate(true)"
                >
                  <LxpLoader v-if="isDownloadLoading" />
                  <img
                    src="@/assets/images/my-achievements/download.svg"
                    alt="download"
                    v-else
                  />
                  <p>{{ $t("general.download") }}</p>
                </div>
                <div
                  class="ma-details__cta--share"
                  v-if="showShareCertificateAlert"
                >
                  <img
                    src="@/assets/images/courseware/copy-check.svg"
                    alt="check"
                    width="20"
                  />
                  <p>{{ $t("course.copied_to_clipboard") }}</p>
                </div>
                <div
                  class="ma-details__cta--share"
                  @click="shareCertificate"
                  v-else
                >
                  <img
                    src="@/assets/images/my-achievements/share.svg"
                    alt="share"
                  />
                  <p>{{ $t("course.share") }}</p>
                </div>
              </div>
            </div>
            <div class="ma-details__content--img">
              <div class="ma-details__img--div">
                <img
                  src="@/assets/images/certificates/certificate-badge.svg"
                  alt="certificate badge"
                />
                <h5>{{ $t("achievements.certificate.certification") }}</h5>
                <p>
                  {{ $t("achievements.certificate.recognition_of_learning") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="ma-details__certificate"
          @click="viewCertificate"
          v-if="certificateDetail.is_certificate_available"
        >
          <h6 class="ma-details__credentials--title">
            {{ $t("my_achievements.detail.certificate.title") }}
          </h6>
          <div class="ma-details__certificate--div">
            <img
              src="@/assets/images/my-achievements/certificate/badge-purple.svg"
              alt="badge-blue"
              width="24"
              height="24"
            />
            <p>{{ $t("my_achievements.detail.certificate.title") }}</p>
            <span>{{ certificateDetail.created_date | formatDate }}</span>
          </div>
        </div>
        <div
          class="ma-details__credentials"
          v-if="certificateDetail.module_level_credentials.length > 0"
        >
          <div class="ma-details__credentials--heading">
            <h6 class="ma-details__credentials--title">
              {{ $t("my_achievements.detail.credentials.title") }}
            </h6>
            <div class="ma-details__credentials--heading-grid">
              <div class="d-flex align-items-center">
                <div
                  @click="grid = true"
                  :class="['c-pointer view-columns__icon', { active: grid }]"
                >
                  <img
                    :src="require(`@/assets/images/my-achievements/grid.svg`)"
                    alt="badge"
                    width="20"
                    height="20"
                    v-b-tooltip.hover
                    :title="$t('my_achievements.detail.tooltip.view_column')"
                    placement="rightbottom"
                  />
                </div>
                <div
                  @click="grid = false"
                  :class="[
                    'c-pointer ml-lg-3 ml-md-3 ml-2 view-list__icon',
                    { active: !grid }
                  ]"
                >
                  <img
                    :src="require(`@/assets/images/my-achievements/list.svg`)"
                    alt="badge"
                    width="20"
                    height="20"
                    v-b-tooltip.hover
                    :title="$t('my_achievements.detail.tooltip.view_list')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            :class="[
              'ma-details__credentials--div',
              grid ? 'grid' : 'list',
              credentialsDivClass
            ]"
          >
            <Microcredentials
              :grid="grid"
              v-for="credential in certificateDetail.module_level_credentials"
              :key="credential.key"
              :credential="credential"
              :courseId="courseId"
            ></Microcredentials>
          </div>
        </div>
      </div>
    </div>
  </LayoutManager>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpLoader } from "didactica";
import LayoutManager from "@/components/Layout/LayoutManager.vue";
import Microcredentials from "@/components/Achievements/Microcredentials.vue";
// mixins
import downloadShare from "./mixins/downloadShare";
export default {
  mixins: [downloadShare],
  components: { LxpLoader, LayoutManager, Microcredentials },
  data() {
    return {
      isLoading: true,
      grid: true,
      showShareCertificateAlert: false
    };
  },
  computed: {
    ...mapGetters(["certificateDetail"]),
    credentialsDivClass() {
      let className;
      if (this.certificateDetail.module_level_credentials) {
        let listLength = this.certificateDetail.module_level_credentials.length;
        if (this.grid && listLength > 0) {
          if (listLength === 1) {
            className = "ma-details__credentials--one";
          } else if (listLength === 2) {
            className = "ma-details__credentials--two";
          }
        }
      }
      return className;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCertificateDetail();
    });
  },
  methods: {
    shareCertificate() {
      // TODO:: Move Share URL to a dedicated component and resue it
      let url = this.certificateDetail.share_url_consolidated;

      var input = document.createElement("input");
      input.setAttribute("value", url);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.showShareCertificateAlert = true;
      setTimeout(() => {
        this.showShareCertificateAlert = false;
      }, 5000);
    },
    navigateTo(name) {
      this.$router.push({ name: name }).catch(() => {});
    },
    viewCertificate() {
      this.$router
        .push({
          path: `/my-achievements/${this.courseId}/awards/certificate`
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.ma-details__wrapper {
  margin: 40px 50px 0;
  text-align: left;
  display: flex;
  .ma-details__left {
    img {
      cursor: pointer;
    }
  }
  .ma-details__right {
    margin: 0 50px 0 20px;
    flex-grow: 1;
    .ma-details--top {
      padding-bottom: 16px;
      border-bottom: 1px solid $brand-neutral-200;
      h4 {
        flex-grow: 1;
        @include subtitle-large($brand-neutral-900);
        margin-bottom: 16px;
      }
      .ma-details___content {
        display: flex;
        justify-content: space-between;
        .ma-details___content--left {
          width: 75%;

          .ma-details__subtitle {
            @include subtitle-regular;
            margin-bottom: 8px;
            color: $brand-neutral-700;
          }
          .ma-details___description {
            @include body-regular;
            margin-bottom: 16px;
          }
          .ma-details__cta {
            display: flex;
            > div {
              @include flex-horizontal-center;
              margin-right: 16px;
              cursor: pointer;
              &:last-child {
                margin-right: 0;
              }
              p {
                margin: 0 0 0 8px;
                @include label-large($brand-primary);
              }
            }
          }
        }
      }
      .ma-details__content--img {
        background: $brand-primary-100;
        border: 1px solid $brand-primary-400;
        padding: 15px 13px;
        border-radius: 8px;
        width: 245px;
        display: flex;
        .ma-details__img--div {
          text-align: center;
          h5 {
            @include label-large($brand-primary-400, 500);
            margin-bottom: 0;
          }
          p {
            @include label-medium;
            color: $brand-primary-400;
            margin-bottom: 0;
          }
        }
      }
    }
    .ma-details__credentials,
    .ma-details__certificate {
      padding-top: 17px;
      .ma-details__credentials--heading {
        @include horizontal-space-between;
        margin-bottom: 16px;
        .ma-details__credentials--heading-grid {
          display: flex;
          align-items: center;
          .view-columns__icon,
          .view-list__icon {
            display: flex;
            padding: 4px;
            &:hover,
            &.active {
              background: $brand-primary-100;
              border-radius: 4px;
            }
          }
        }
        .ma-details__credentials--title {
          margin-bottom: 0;
        }
      }
      .ma-details__credentials--title {
        @include label-large($brand-neutral-600, 500);
        margin-bottom: 16px;
      }
      .ma-details__credentials--div {
        transition: all 0.1s ease-in;
        &.list {
          background: $brand-neutral-0;
          box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          padding: 8px;
        }
        &.grid {
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax(clamp(33% - 1em, 245px, 100%), 1fr)
          );
          gap: 1em;
          &.ma-details__credentials--one {
            grid-template-columns: 100%;
          }
          &.ma-details__credentials--two {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
      .ma-details__certificate--div {
        padding: 16px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
        @include flex-horizontal-center;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
        }
        p {
          @include body-regular($brand-neutral-1000);
          margin: 0 0 0 16px;
          flex-grow: 1;
        }
        span {
          @include label-small;
          color: $brand-neutral-300;
        }
      }
    }
    .ma-details__credentials {
      padding-bottom: 17px;
    }
  }
}
@media screen and (max-width: 480px) {
  #my-achievements-details {
    margin: 0;
  }
}
</style>
